










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FingerprintCapture from '../fingerprintCapture/CaptureModal.vue'
import SignatureCapture from '../signature/CaptureModal.vue'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import Loading from '@/components/Loading.vue'
import Chance from 'chance'
import moment from '@/plugins/moment'
import gql from 'graphql-tag'
import axios from 'axios'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    Loading
  }
})
export default class DigitalSignatureWizard extends Vue {
  @Prop({ type: Boolean }) open!: boolean
  @Prop({ type: String }) docName!: string
  @Prop({ type: String }) fieldName!: string
  @Prop({ type: Uint8Array }) pdfBytes!: Uint8Array
  @Prop({ type: Number, required: true }) targetPage!: number
  @Prop({ type: Number, required: true }) xPos!: number
  @Prop({ type: Number, required: true }) yPos!: number
  @Prop({ type: Object, required: true }) environmentVariables!: Record<
    string,
    any
  >

  working = false
  currentStatus = ''
  connection: WebSocket | null = null
  progress = 0

  get isOpen() {
    return this.open
  }

  set isOpen(v: boolean) {
    this.$emit('update:open', v)
  }

  close() {
    this.isOpen = false
  }

  async start() {
    try {
      this.working = true
      this.currentStatus = String(this.$t('document_digital.script.start.status'))
      const { data: tokenRequest } = await this.$apollo.mutate({
        mutation: gql`
          mutation getHsmToken(
            $environmentId: ID
            $formId: ID
            $fieldName: String
            $posX: Float
            $posY: Float
            $pageNum: Float
          ) {
            result: getSigningToken(
              environmentId: $environmentId
              formId: $formId
              fieldName: $fieldName
              posX: $posX
              posY: $posY
              pageNum: $pageNum
            )
          }
        `,
        variables: {
          environmentId: this.environmentVariables.environmentId,
          formId: this.environmentVariables.formId,
          fieldName: this.fieldName,
          posX: this.xPos,
          posY: this.yPos,
          pageNum: this.targetPage
        }
      })
      const {
        result: { token, docUrl, docFields, docKey }
      } = tokenRequest
      console.log(docUrl, docKey)
      this.currentStatus =
        String(this.$t('document_digital.script.start.gettingConnection'))
      const documentId = new Chance().string({
        length: 6,
        pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghyjklmnopqrstuvwxyz0123456789'
      })

      await new Promise((resolve, reject) => {
        this.connection = new WebSocket(getEnv('VUE_APP_INTEGRATIONS_WS')!)
        this.connection.onopen = async (ev) => {
          try {
            this.currentStatus =
              String(this.$t('document_digital.script.start.sendingFile'))
            const pdfBlob = new Blob([this.pdfBytes], {
              type: 'application/pdf'
            })
            const { data: upload } = await axios({
              url: docUrl,
              method: 'post',
              transformRequest: [
                (data) => {
                  const formData = new FormData()
                  for (const key in data) {
                    if (!data.hasOwnProperty(key)) continue
                    if (key === 'file') {
                      formData.append(key, data[key], this.docName)
                    } else {
                      formData.append(key, data[key])
                    }
                  }
                  return formData
                }
              ],
              data: {
                ...docFields,
                file: pdfBlob
              },
              onUploadProgress: (progress) => {
                this.progress = (progress.loaded / progress.total) * 100
              }
            })
            this.progress = 0
            this.connection!.send(
              JSON.stringify({
                action: 'hsmSignDocument',
                data: {
                  token,
                  documentId
                }
              })
            )
          } catch (e) {
            reject(e)
          }
        }
        this.connection.onmessage = async (ev) => {
          try {
            const msg = JSON.parse(ev.data) as { action: string; data: any }
            switch (msg.action) {
              case 'hsmSignRequestReceived':
                this.currentStatus =
                  String(this.$t('document_digital.script.start.sendingRequest'))
                break
              case 'hsmSignRequestQueued':
                this.currentStatus = String(this.$t('document_digital.script.start.waiting'))
                break
              case 'hsmSignedDocument':
                this.currentStatus = String(this.$t('document_digital.script.start.downloading'))
                const { data } = await axios.get(msg.data.url, {
                  responseType: 'arraybuffer',
                  onDownloadProgress: (progress) => {
                    this.progress = (progress.loaded / progress.total) * 100
                  }
                })
                this.$emit('pdfUpdated', new Uint8Array(data))
                resolve()
                this.close()
                break
              case 'error':
                reject(msg.data)
                break
            }
          } catch (e) {
            reject(e)
          }
        }
        this.connection.onerror = (e) => reject
      })
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000
      })
      this.close()
    } finally {
      this.working = false
      this.progress = 0
      if (this.connection && this.connection.OPEN) this.connection.close()
    }
  }
}
